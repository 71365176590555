<template>
  <Page>
    <div class="home-page">
      <zui-header />
      <div class="content">
        <div class="logo-box">
          <img src="https://www.achencybe.com/img/pc/logo.png">
        </div>
        <div class="code-box">
          <template v-if="show">
            <div style="margin-top:-30px;font-size:30px;">Achen&Cybe感谢您的订购。</div>
            <div style="font-size:18px;margin-top:5px;font-weight:100">可移步至个人中心以查看订单状态。</div>
          </template>
          <template v-else>
            正在查询订单状态...
          </template>
        </div>
      </div>
      <zui-footer />
    </div>
  </Page>
</template>

<script>

import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";
export default {
  name: "footerDetail",
  components: { Page, ZuiHeader, ZuiFooter },
  computed:{
    type(){
      return this.$route.query.type
    }
  },
  data(){
    return {
      text:'正在查询订单状态...',
      show: false
    }
  },
  mounted(){
    if(this.type === 'PayPal'){
      const {
        token,
        PayerID,
        orderNumber
      } = this.$route.query
      this.$api.captureOrder({
        token,
        PayerID,
        orderNumber
      })
        .then(res => {
          if(res.code === 0){
            this.show = true;
          }else{
            this.text = '订单状态异常，请联系客服。';
          }
        })
    }else{
      this.show = true;
    }
  }
};
</script>

<style scoped lang="scss">
.content{
  width:54%;
  margin:200px auto 200px;
  box-shadow: #0c0c0c -5px 9px 17px 4px;
  .logo-box{
    width:100%;
    padding:100px 0;
    background-color:#121212;
    img{
      width:20%;
    }
  }
  .code-box{
    background-color: #fff;
    width:100%;
    padding:3% 0;
    height:700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
