var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "logo-box" }, [
            _c("img", {
              attrs: { src: "https://www.achencybe.com/img/pc/logo.png" },
            }),
          ]),
          _c(
            "div",
            { staticClass: "code-box" },
            [
              _vm.show
                ? [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "-30px",
                          "font-size": "30px",
                        },
                      },
                      [_vm._v("Achen&Cybe感谢您的订购。")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "margin-top": "5px",
                          "font-weight": "100",
                        },
                      },
                      [_vm._v("可移步至个人中心以查看订单状态。")]
                    ),
                  ]
                : [_vm._v(" 正在查询订单状态... ")],
            ],
            2
          ),
        ]),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }